import { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Rating from '../../components/Rating'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import CustomSlide from '../../components/CustomSlide'
import { FiExternalLink } from 'react-icons/fi'
import { useCreateReviewMutation } from '../../store/apis/tailorTshirtsApi'
import { useGetTailorTshirtDetailsQuery } from '../../store/apis/tailorTshirtsApi'
import { toast } from 'react-toastify'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { tailorRepeatAddToCart } from '../../store/slices/cartSlice'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Meta from '../../components/Meta'

const TailorTshirtsDetailScreen = () => {
  const { id: tshirtId } = useParams()
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedTag, setSelectedTag] = useState('織りネーム')
  const [selectedSleeveLib, setSelectedSleeveLib] = useState('リブ無し')
  const [selectedPocket, setSelectedPocket] = useState({
    pocketType: '付けない',
    pocketPosition: '左胸',
  })
  const [selectedEmbroidery, setSelectedEmbroidery] = useState({
    isEmbroidery: '無し',
    embroideryChar: '',
    embroideryColor: '',
  })
  const [filteredImage, setFilteredImage] = useState([])
  const [optionPriceLib, setOptionPriceLib] = useState(0)
  const [optionPricePocket, setOptionPricePocket] = useState(0)
  const [optionPriceEmbroidery, setOptionPriceEmbroidery] = useState(0)
  const [displayName, setDisplayName] = useState('')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    data: item,
    isLoading,
    refetch,
    error,
  } = useGetTailorTshirtDetailsQuery(tshirtId)

  const [createReview, { isLoading: loadingReview }] = useCreateReviewMutation()

  const { userInfo } = useSelector((state) => state.auth)
  const { tailorRepeat } = useSelector((state) => state.tailorSpec)

  useEffect(() => {
    if (item) {
      setSelectedColor(item.colors[0].colorName)
    }
  }, [item])

  useEffect(() => {
    if (selectedColor) {
      const filteredColor = item.colors.find(
        (i) => i.colorName === selectedColor
      )
      setFilteredImage(filteredColor.images)
    }
  }, [item, selectedColor])

  const customSlideHandler = (item) => {
    dispatch(changeCustomSlideState(item))
  }

  // change status function
  const changeTag = (value) => {
    setSelectedTag(value)
  }
  const changeSleevLib = (value, price) => {
    setSelectedSleeveLib(value)
    setOptionPriceLib((prev) => (value === 'リブ付き' ? prev + price : 0))
  }
  const changePocket = (value, price) => {
    setSelectedPocket(value)
    setOptionPricePocket((prev) =>
      value.pocketType !== '付けない' ? prev + price : 0
    )
  }
  const changeEmbroidery = (value, price) => {
    setSelectedEmbroidery(value)
    setOptionPriceEmbroidery((prev) =>
      value.isEmbroidery === '有り' ? prev + price : 0
    )
  }

  console.log(optionPriceLib, optionPricePocket, optionPriceEmbroidery)

  const addToCartHandler = (e) => {
    e.preventDefault()

    const colorImage = item.colors.find((x) => x.colorName === selectedColor)
      .images[0]

    dispatch(
      tailorRepeatAddToCart({
        _id: item._id,
        name: item.name,
        category: item.category,
        price:
          item.repeatPrice +
          optionPriceLib +
          optionPricePocket +
          optionPriceEmbroidery,
        image: colorImage,
        color: selectedColor,
        size: tailorRepeat.size,
        tag: selectedTag,
        lib: selectedSleeveLib,
        pocket: selectedPocket,
        embroidery: selectedEmbroidery,
        qty: 1,
        isNew: false,
        fixSize: tailorRepeat.fixSize,
        cartId:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
      })
    )
    navigate('/cart')
  }

  const reviewPostHandler = async () => {
    if (displayName === '') {
      toast.error('ニックネームをご記入ください')
      return
    } else if (rating === 0) {
      toast.error('評価を選んでください')
      return
    } else if (comment === '') {
      toast.error('コメントをご記入ください')
      return
    } else {
      try {
        await createReview({
          tshirtId,
          displayName,
          rating,
          comment,
        }).unwrap()
        refetch()
        toast.success('レビューが投稿されました')
        setRating(0)
        setComment('')
      } catch (error) {
        toast.error(error.data.message)
      }
    }
  }

  return (
    <div data-theme="black" className="grow flex justify-center">
      <div className="screen-container mt-5 w-full">
        {item && (
          <CustomSlide
            changeTag={changeTag}
            changeSleeveLib={changeSleevLib}
            changePocket={changePocket}
            changeEmbroidery={changeEmbroidery}
            selectedValue={{
              selectedTag,
              selectedSleeveLib,
              selectedPocket,
              selectedEmbroidery,
            }}
            type={item.type}
            selectedColor={selectedColor}
          />
        )}

        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/repeat-order">Tailor T-shirts</Link>
            </li>
            <li>{item && item.name}</li>
          </ul>
        </div>

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>{error.data.message}</Message>
        ) : (
          <>
            <Meta title={`${item.name} | m&s Fabric`} />
            <div className="lg:hidden mb-5">
              <h3 className="text-2xl font-semibold italic mb-2 flex items-center">
                {item.newItem && (
                  <span className="text-sm bg-amber-600 rounded-full px-2 py-0.5 text-white mr-2">
                    New
                  </span>
                )}
                {item.name}
              </h3>
              <div className="flex items-center mb-5">
                <p className="text-xl mr-5">
                  {item.repeatPrice.toLocaleString()}
                  <span className="text-xs">円（税込）</span>
                </p>
                <AnchorLink offset="50" href="#rating">
                  <Rating
                    value={
                      item.reviews.reduce((acc, cur) => acc + cur.rating, 0) /
                      item.reviews.length
                    }
                    total={item.reviews.length}
                    starColor="text-yellow-700"
                    textColor="text-white"
                  />
                </AnchorLink>
              </div>
              {item.features.map((feature, index) => (
                <span
                  key={index}
                  className="text-xs border border-neutral-600 py-0.5 px-2 rounded-full mr-1 inline-block mb-1"
                >
                  {feature}
                </span>
              ))}
            </div>

            <div className="w-full flex flex-col lg:flex-row lg:gap-10">
              <Swiper
                className="w-full lg:basis-1/2 xl:basis-2/3"
                slidesPerView={1}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                  1280: {
                    slidesPerView: 2,
                  },
                }}
              >
                {filteredImage.map((i, index) => (
                  <SwiperSlide key={index}>
                    <LazyLoadImage
                      className="block w-full h-full object-cover"
                      src={i}
                      alt={index}
                      effect="blur"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* )} */}

              {/* フォーム */}
              <div className="basis-full  md:basis-1/3 lg:basis-1/2 xl:basis-1/3">
                <div className="hidden mb-10 lg:block">
                  <h3 className="text-2xl font-semibold italic mb-2 flex items-center">
                    {item.newItem && (
                      <span className="text-sm bg-amber-600 rounded-full px-2 py-0.5 text-white mr-2">
                        New
                      </span>
                    )}
                    {item.name}
                  </h3>

                  <div className="flex items-center mb-3">
                    <p className="text-xl mr-5">
                      {item.repeatPrice.toLocaleString()}
                      <span className="text-xs">円（税込）</span>
                    </p>

                    <AnchorLink offset="50" href="#rating">
                      <Rating
                        value={
                          item.reviews.reduce(
                            (acc, cur) => acc + cur.rating,
                            0
                          ) / item.reviews.length
                        }
                        total={item.reviews.length}
                        starColor="text-yellow-700"
                        textColor="text-white"
                      />
                    </AnchorLink>
                  </div>
                  {item.features.map((feature, index) => (
                    <span
                      key={index}
                      className="text-xs border border-neutral-600 py-0.5 px-2 rounded-full mr-1 inline-block mb-1"
                    >
                      {feature}
                    </span>
                  ))}
                </div>

                {/* 商品説明モーダル */}
                <div className="mt-5">
                  <button
                    className="w-full border-y border-neutral-600 text-sm font-normal flex items-center pt-1.5 pb-1 px-2 hover:border-neutral-400"
                    onClick={() =>
                      document.getElementById('my_modal_3').showModal()
                    }
                  >
                    <FiExternalLink className="mr-2" /> 商品概要
                  </button>
                  <dialog id="my_modal_3" className="modal">
                    <div data-theme="lofi" className="modal-box">
                      <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                      </form>
                      <div className="overflow-x-auto p-5">
                        <h4 className="font-semibold mb-5">
                          {item.name}の商品概要
                        </h4>
                        <table className="table">
                          <tbody>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">説明</th>
                              <td className="text-sm/6">{item.description}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">素材</th>
                              <td className="text-sm/6">{item.fabric}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">取扱</th>
                              <td className="text-sm/6">{item.care}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </dialog>
                </div>

                <form onSubmit={addToCartHandler}>
                  {/* カラーセレクト */}
                  <div className="px-3 mt-7 border-b border-neutral-600 pb-2">
                    <div className="mb-2 text-sm">
                      <span>カラー :</span>
                      <span className="text-sm font-semibold ml-6">
                        {selectedColor}
                      </span>
                    </div>
                    <div className="flex">
                      {item.colors.map((color) => (
                        <div key={color.colorName}>
                          <input
                            type="radio"
                            name="colors"
                            value={color.colorName}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            id={color.colorName}
                            className="hidden colorBadge"
                            checked={color.colorName === selectedColor}
                          />
                          <label
                            htmlFor={color.colorName}
                            style={{ backgroundColor: `${color.colorBg}` }}
                            className="relative w-6 h-6 inline-block rounded-full border border-gray-500 mr-2 colorBadge-checked:border-white cursor-pointer"
                          >
                            <div className="colorChecked2 absolute inset-0 w-[22px] h-[22px] rounded-full border border-gray-700 hidden"></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* カスタマイズ項目 */}
                  <div>
                    <ul className="text-sm">
                      <li className="py-3 border-b border-neutral-600 flex justify-between items-center px-3">
                        <div>
                          <span>タグ :</span>
                          <span className="font-semibold ml-7 text-[13px]">
                            {selectedTag}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => customSlideHandler('tag')}
                          className="btn btn-outline btn-xs text-xs font-light border-neutral-400"
                        >
                          変更
                        </button>
                      </li>
                      <li className="py-3 border-b border-neutral-600 flex justify-between items-center px-3">
                        <div>
                          <span>袖リブ :</span>
                          <span className="font-semibold ml-7 text-[13px]">
                            {selectedSleeveLib}
                            {selectedSleeveLib === 'リブ付き' && (
                              <span>（+990円）</span>
                            )}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => customSlideHandler('lib')}
                          className="btn btn-outline btn-xs text-xs font-light border-neutral-400"
                        >
                          オプション
                        </button>
                      </li>
                      <li className="py-3 border-b border-neutral-600 flex justify-between items-center px-3">
                        <div>
                          <span>ポケット :</span>
                          <span className="font-semibold ml-4 text-[13px]">
                            {selectedPocket.pocketType !== '付けない' &&
                              selectedPocket.pocketPosition}
                            {selectedPocket.pocketType !== '付けない' && (
                              <span> / </span>
                            )}
                            {selectedPocket.pocketType}
                            {selectedPocket.pocketType !== '付けない' && (
                              <span>（+1,320円）</span>
                            )}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => customSlideHandler('pocket')}
                          className="btn btn-outline btn-xs text-xs font-light border-neutral-400"
                        >
                          オプション
                        </button>
                      </li>
                      <li className="py-3 border-b border-neutral-600 flex justify-between items-center px-3">
                        <div>
                          <span>名入れ :</span>
                          <span className="font-semibold ml-4 text-[13px]">
                            {selectedEmbroidery.isEmbroidery !== '無し' ? (
                              <span>
                                {selectedEmbroidery.embroideryChar} /{' '}
                                {selectedEmbroidery.embroideryColor}（+1,650円）
                              </span>
                            ) : (
                              <span>{selectedEmbroidery.isEmbroidery}</span>
                            )}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => customSlideHandler('embroidery')}
                          className="btn btn-outline btn-xs text-xs font-light border-neutral-400"
                        >
                          オプション
                        </button>
                      </li>
                    </ul>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary w-full mt-10"
                  >
                    カートに入れる
                  </button>
                </form>
              </div>
            </div>

            {/* レビュー */}
            {loadingReview ? (
              <Loader />
            ) : (
              <div id="rating" className="w-full mt-10 mb-20">
                <div className="flex items-center mb-5">
                  <h3 className="font-bold italic text-2xl mr-5">Reviews</h3>
                  {userInfo ? (
                    <button
                      className="btn btn-outline btn-xs font-light"
                      onClick={() =>
                        document.getElementById('post_review_modal').showModal()
                      }
                    >
                      レビューを書く
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline btn-sm hover:btn-primary"
                      onClick={() => navigate('/login')}
                    >
                      ログインして投稿する
                    </button>
                  )}
                </div>
                {item.reviews.length === 0 && (
                  <Message variant={'alert-info'}>レビューはありません</Message>
                )}

                <div className="grid gap-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                  {item.reviews.map((review) => (
                    <div
                      className="border border-gray-300 p-3 rounded"
                      key={review._id}
                    >
                      <p className="text-sm">
                        {review.createdAt.substring(0, 10)}
                      </p>
                      <p className="text-sm mb-2">{review.displayName}</p>

                      <Rating
                        value={review.rating}
                        starColor="text-sky-700"
                        textColor="text-black"
                      />
                      <p className="mt-3 text-sm">{review.comment}</p>
                    </div>
                  ))}
                </div>

                {/* 投稿フォームモーダル */}
                <dialog
                  data-theme="lofi"
                  id="post_review_modal"
                  className="modal modal-bottom sm:modal-middle"
                >
                  <div className="modal-box" data-theme="lofi">
                    <h3 className="font-bold text-md mb-5">
                      この商品の評価とコメントを記入ください
                    </h3>

                    <form method="dialog">
                      <input
                        type="text"
                        placeholder="ニックネーム"
                        className="input input-bordered w-full mb-3 text-sm"
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                      <label className="form-control mb-3">
                        <select
                          className="select select-bordered"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option defaultValue>評価を選んでください</option>
                          <option value="5">5 - とても良かった</option>
                          <option value="4">4 - 良かった</option>
                          <option value="3">3 - 普通</option>
                          <option value="2">2 - あまり良くなかった</option>
                          <option value="1">1 - 全然良くなかった</option>
                        </select>
                      </label>
                      <textarea
                        className="textarea textarea-bordered block w-full mb-5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <button className="btn mr-2">閉じる</button>
                      <button
                        className="btn btn-primary"
                        onClick={reviewPostHandler}
                      >
                        投稿する
                      </button>
                    </form>
                  </div>
                </dialog>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default TailorTshirtsDetailScreen
