const fabricSampleMail = (info) => {
  return `
  ${info.lastname} ${info.firstname} 様

  この度は生地見本サンプルをご請求いただきありがとうございます。
  以下の内容にて受付させていただきました。

  ■ 配送先
  --------------------------------------------------------------------------------------------------------
  【お名前】${info.lastname} ${info.firstname}（${info.lastnameYomi} ${
    info.firstnameYomi
  }）
  【メールアドレス】${info.email}
  【電話番号】${info.phoneNum}
  【配送先住所】〒${info.postalCode} ${info.prefecture}${info.city}${
    info.address
  } ${info.building}

  ■ ご請求内容
  --------------------------------------------------------------------------------------------------------
  ${info.fabricSamples.map((sample, index) => {
    return `【生地-${index + 1}】${sample.fabric} / ${sample.color}\n`
  })}
  --------------------------------------------------------------------------------------------------------

  内容確認後、一週間以内に発送させていただきます。
  その他、Tシャツに関してお困りの事やご要望などございましたらお気軽にご連絡ください。
  今後ともよろしくお願いいたします。

  --------------------------------------------------------------------------------------------------------
  m&s Fabric | オーダーTシャツ専門店
  〒261-0023 千葉県千葉市美浜区中瀬1-3 幕張テクノガーデンCB棟3階 MBP
  `
}

export { fabricSampleMail }
