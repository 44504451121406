import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Rating from './Rating'

const CustomIndexItem = ({ item }) => {
  return (
    <div key={item._id} className="flex flex-col justify-between mb-10">
      <div>
        <Link to={`/customize-tshirts/${item._id}`}>
          <LazyLoadImage
            src={item.image}
            alt={item.name}
            className="mb-3"
            height={item.image.height}
            width={item.image.width}
            effect="blur"
          />
        </Link>
        <h3 className="font-semibold italic text-lg text-md mb-2 flex items-center">
          {item.newItem && (
            <span className="text-xs bg-amber-600 rounded-full px-2 py-0.5 text-white mr-2">
              New
            </span>
          )}
          {item.name}
        </h3>
        <ul className="flex mb-2">
          {item.colors.map((color, index) => (
            <li
              key={index}
              style={{ backgroundColor: `${color.colorBg}` }}
              className="w-4 h-4 rounded-full border border-neutral-500 mr-0.5"
            ></li>
          ))}
        </ul>
        <Rating
          value={
            item.reviews.reduce((acc, cur) => acc + cur.rating, 0) /
            item.reviews.length
          }
          total={item.reviews.length}
          starColor="text-sky-700"
          textColor="text-black"
        />
        <p className="my-2">
          {item.features.map((feature, index) => (
            <span
              key={index}
              className="text-xs border border-gray-300 py-0.5 px-2 rounded-full mr-1 inline-block mb-1"
            >
              {feature}
            </span>
          ))}
        </p>
      </div>
      <p className="text-lg">
        {item.price.toLocaleString()}
        <span className="text-xs">円（税込）</span>
      </p>
    </div>
  )
}

export default CustomIndexItem
